/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-green-circle-badge',
  template: `
    <svg
      class="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <ng-content></ng-content>
  `,
  host: {
    class: 'badge bg-green-100 text-green-800',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreenCircleBadgeComponent {
  constructor() {}
}
