import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-info-text',
  templateUrl: './info-text.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTextComponent {
  constructor() {}
}
