import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-standard-section-spacing',
  template: ` <ng-content></ng-content> `,
  host: {
    class: 'block px-4 py-5 md:px-6 md:py-5',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardSectionSpacingComponent {}
