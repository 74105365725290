import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-no-data',
  templateUrl: './no-data.component.html',
  styles: [],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {}
