import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { GreenCircleBadgeComponent } from './circle-badges/green-badge/green-badge.component';
import { GrayCircleBadgeComponent } from './circle-badges/grey-badge/grey-badge.component';
import { RedCircleBadgeComponent } from './circle-badges/red-badge/red-badge.component';
import { YellowCircleBadgeComponent } from './circle-badges/yellow-badge/yellow-badge.component';
import { CircleBaseComponent } from './circles/circle-base/circle-base.component';
import { CircleSmComponent } from './circles/circle-sm/circle-sm.component';
import { InfoTextComponent } from './hints/info-text/info-text.component';
import { HelpLinkComponent } from './links/help-link/help-link.component';
import { NoDataComponent } from './no-data/no-data.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, CircleBaseComponent],
  declarations: [
    GreenCircleBadgeComponent,
    RedCircleBadgeComponent,
    GrayCircleBadgeComponent,
    YellowCircleBadgeComponent,
    HelpLinkComponent,
    InfoTextComponent,
    CircleSmComponent,
    NoDataComponent,
  ],
  exports: [
    GreenCircleBadgeComponent,
    RedCircleBadgeComponent,
    GrayCircleBadgeComponent,
    YellowCircleBadgeComponent,
    HelpLinkComponent,
    InfoTextComponent,
    CircleSmComponent,
    NoDataComponent,
  ],
})
export class FrontendSharedUiElementsModule {}
