import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-flex-between',
  templateUrl: './flex-between.component.html',
  styles: [],
  host: {
    class:
      'block space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexBetweenComponent {
  constructor() {}
}
