import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CardComponent } from '../card/card.component';
import { CardContentComponent } from '../card-content/card-content.component';
import { NavCardWrapperComponent } from '../nav-card-wrapper/nav-card-wrapper.component';

@Component({
  selector: 'mspot-nav-card',
  template: `
    <mspot-nav-card-wrapper>
      <mspot-card class="rounded-lg">
        <mspot-card-content>
          <ng-content></ng-content>
        </mspot-card-content>
      </mspot-card>
    </mspot-nav-card-wrapper>
  `,
  styles: [],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NavCardWrapperComponent, CardComponent, CardContentComponent],
})
export class NavCardComponent {}
