import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-red-circle-badge',
  template: `
    <svg
      class="-ml-1 mr-1.5 h-2 w-2 text-red-400"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <ng-content></ng-content>
  `,
  host: {
    class: 'badge bg-red-100 text-red-800',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedCircleBadgeComponent {
  constructor() {}
}
