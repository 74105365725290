import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-circle-base',
  standalone: true,
  templateUrl: './circle-base.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleBaseComponent {}
