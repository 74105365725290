import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-grid-cols-two',
  templateUrl: './grid-cols-two.component.html',
  styles: [],
  host: {
    class: 'block space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-6',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColsTwoComponent {}
