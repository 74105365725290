import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FrontendSharedUiElementsModule } from '@memberspot/frontend/shared/ui/elements';

import { CardComponent } from './cards/card/card.component';
import { CardContentComponent } from './cards/card-content/card-content.component';
import { HeadingWrapperComponent } from './cards/heading-wrapper/heading-wrapper.component';
import { ItemCardComponent } from './cards/item-card/item-card.component';
import { NavCardComponent } from './cards/nav-card/nav-card.component';
import { NavCardHeadingComponent } from './cards/nav-card-heading/nav-card-heading.component';
import { NavCardRawComponent } from './cards/nav-card-raw/nav-card-raw.component';
import { NavCardWrapperComponent } from './cards/nav-card-wrapper/nav-card-wrapper.component';
import { NavCardWrapperXComponent } from './cards/nav-card-wrapper-x/nav-card-wrapper-x.component';
import { FlexBetweenComponent } from './flex/flex-between/flex-between.component';
import { GridColsTwoComponent } from './grid/grid-cols-two/grid-cols-two.component';
import { GridColsTwoLgDirective } from './grid/grid-cols-two-lg.directive';
import { HeaderHelplinkComponent } from './headers/header-helplink/header-helplink.component';
import { StandardSectionComponent } from './sections/standard-section/standard-section.component';
import { StandardSectionSpacingComponent } from './sections/standard-section-spacing/standard-section-spacing.component';
import { StandardSectionVerticalComponent } from './sections/standard-section-vertical/standard-section-vertical.component';
import { NoDataMessageDirective } from './text-blocks/no-data-message.directive';

@NgModule({
  imports: [
    CommonModule,
    FrontendSharedUiElementsModule,
    NavCardWrapperComponent,
    NavCardComponent,
    ItemCardComponent,
    CardContentComponent,
    CardComponent,
    HeadingWrapperComponent,
    NavCardHeadingComponent,
    NavCardWrapperXComponent,
  ],
  declarations: [
    NavCardRawComponent,
    StandardSectionComponent,
    StandardSectionSpacingComponent,
    StandardSectionVerticalComponent,
    GridColsTwoComponent,
    FlexBetweenComponent,
    GridColsTwoLgDirective,
    NoDataMessageDirective,
    HeaderHelplinkComponent,
  ],
  exports: [
    ItemCardComponent,
    CardContentComponent,
    CardComponent,
    NavCardWrapperComponent,
    NavCardRawComponent,
    NavCardComponent,
    StandardSectionComponent,
    StandardSectionSpacingComponent,
    StandardSectionVerticalComponent,
    GridColsTwoComponent,
    FlexBetweenComponent,
    GridColsTwoLgDirective,
    NoDataMessageDirective,
    HeadingWrapperComponent,
    NavCardHeadingComponent,
    NavCardWrapperXComponent,
    HeaderHelplinkComponent,
    NavCardWrapperXComponent,
  ],
})
export class FrontendSharedUiLayoutModule {}
