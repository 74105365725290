import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-wrapper-x',
  template: `<ng-content></ng-content>`,
  styles: [],
  host: {
    class: 'px-4 block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  standalone: true,
})
export class NavCardWrapperXComponent {}
