import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-standard-section-vertical',
  template: `
    <div>
      <h3 class="title-sm">{{ heading }}</h3>
      <div class="subtitle-sm">{{ subtitle }}</div>
    </div>
    <div class="mt-5">
      <ng-content></ng-content>
    </div>
  `,
  host: {
    class: 'block px-4 py-5 md:px-6 md:py-5',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardSectionVerticalComponent {
  @Input() heading = '';
  @Input() subtitle = '';

  constructor() {}
}
