import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CardComponent } from '../card/card.component';

@Component({
  selector: 'mspot-item-card',
  template: `
    <mspot-card class="relative rounded-lg" [class.h-full]="full">
      <ng-content></ng-content>
    </mspot-card>
  `,
  styles: [],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CardComponent],
})
export class ItemCardComponent {
  @Input() full = false;
}
