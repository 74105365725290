import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-yellow-circle-badge',
  template: `
    <svg
      class="text-yelow-400 -ml-1 mr-1.5 h-2 w-2"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <ng-content></ng-content>
  `,
  host: {
    class: 'badge bg-yellow-100 text-yellow-800',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YellowCircleBadgeComponent {
  constructor() {}
}
