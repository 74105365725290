import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-nav-card-raw',
  template: `
    <mspot-nav-card-wrapper>
      <mspot-card class="rounded-lg">
        <ng-content></ng-content>
      </mspot-card>
    </mspot-nav-card-wrapper>
  `,
  styles: [],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardRawComponent {}
