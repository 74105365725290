import { Directive } from '@angular/core';

@Directive({
  selector: '[mspotGridColsTwoLg]',
  host: {
    class: 'block space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-6',
  },
})
export class GridColsTwoLgDirective {
  constructor() {}
}
