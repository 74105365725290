import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mspot-standard-section',
  template: `
    <div class="md:col-span-1">
      <h3 class="title-sm block">{{ heading }}</h3>
      <div class="subtitle-sm block">{{ subtitle }}</div>
      <ng-content select="[slot='side']"></ng-content>
    </div>
    <div class="mt-5 md:col-span-2 md:mt-0">
      <ng-content></ng-content>
    </div>
  `,
  host: {
    class: 'block space-y-5 break-words md:space-y-0 md:grid md:grid-cols-3',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardSectionComponent {
  @Input() heading = '';
  @Input() subtitle = '';
  @Input() hasPadding = true;

  @Input()
  mdGap = 'md:gap-6';

  @HostBinding('class')
  get padding() {
    return {
      'px-4': this.hasPadding,
      'py-5': this.hasPadding,
      'md:px-6': this.hasPadding,
      'md:py-5': this.hasPadding,
      [this.mdGap]: this.mdGap,
    };
  }
}
